import { apiInstance, apiInstanceFormData } from "@/utils/api-instance";
import { ENDPOINTS } from "@/utils/constants";

export const listProduct = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.PRODUCT_LIST, payload);
    return data
}

export const searchProduct = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.PRODUCT_SEARCH, payload);
    return data
}

export const storeProduct = async (payload) => {
    const { data } = await apiInstanceFormData.post(ENDPOINTS.PRODUCT_STORE, payload);
    return data
}

export const showProduct = async (id) => {
    const { data } = await apiInstance.get(ENDPOINTS.PRODUCT_SHOW + `/${id}`);
    return data
}

export const editProduct = async (id, payload) => {
    const { data } = await apiInstanceFormData.post(ENDPOINTS.PRODUCT_EDIT + `/${id}`, payload);
    return data
}

export const deleteProduct = async (id) => {
    const { data } = await apiInstance.delete(ENDPOINTS.PRODUCT_DELETE + `/${id}`);
    return data
}